export default defineStore('team', () => {
  const user = useSupabaseUser()
  const { data: teams, refresh: refreshTeams } = useTeamRows()
  const teamName = ref<string | null>(null)
  const team = computed(() => {
    if (!teamName.value || teams.value.length === 0) {
      return undefined
    }

    const route = useRoute()
    const team = teams.value.find(({ name }) => name === teamName.value)

    if (
      !team &&
      (route.params.teamName || route.params.teamName !== teamName.value)
    ) {
      navigateTo('/')
    }

    return team
  })
  const teamMembersByTeamId = ref<Record<string, AppMember[]>>({})
  const teamMembers = computed(() =>
    team.value ? (teamMembersByTeamId.value[team.value.id] ?? []) : [],
  )
  const selfMember = computed(() => {
    if (!user.value || teamMembers.value.length === 0) {
      return undefined
    }

    const selfMember = teamMembers.value.find(({ id }) => id === user.value?.id)

    if (!selfMember) {
      teamName.value = null
      navigateTo('/')
    }

    return selfMember
  })
  const isOwner = computed(() => selfMember.value?.metadata?.role === 'owner')
  const teamInvites = ref<AppTeamInvite[]>([])
  const throttledRefreshTeams = useThrottleFn(refreshTeams, 1000)
  const throttledRefreshTeamMembersByTeamId = useThrottleFn(
    refreshTeamMembersByTeamId,
    1000,
  )
  const throttledRefreshTeamInvites = useThrottleFn(refreshTeamInvites, 1000)

  async function refreshTeamMembersByTeamId() {
    try {
      const ids = teams.value.map(({ id }) => id)

      if (ids.length === 0) {
        teamMembersByTeamId.value = {}
        return
      }

      const { data, status_code } = await $fetch('/api/team/members', {
        params: {
          team_ids: ids,
        },
        ignoreResponseError: true,
        retry: 0,
      })

      if (status_code === 200 && typeof data === 'object') {
        teamMembersByTeamId.value = (data as Record<string, AppMember[]>) ?? {}
      } else {
        throw new Error('Could not fetch team members.')
      }
    } catch (error) {
      console.error(error)

      teamMembersByTeamId.value = {}
    }
  }

  async function refreshTeamInvites() {
    try {
      const { data, status_code } = await $fetch('/api/team/invites', {
        ignoreResponseError: true,
        retry: 0,
      })

      if (status_code === 200 && typeof data === 'object') {
        teamInvites.value = (data as AppTeamInvite[]) ?? []
      } else {
        throw new Error('Could not fetch team members.')
      }
    } catch (error) {
      console.error(error)

      teamInvites.value = []
    }
  }

  function setTeamByName(name: string | null) {
    teamName.value = name
  }

  watch(
    teams,
    async (value) => {
      if (value.length === 0) {
        return
      }

      await throttledRefreshTeamMembersByTeamId()
    },
    { immediate: true, deep: true },
  )

  watch(user, async (value) => {
    if (value) {
      await throttledRefreshTeams()
      await throttledRefreshTeamInvites()
    } else {
      teamName.value = null
      teams.value = []
    }
  })

  useRealtime(async (event) => {
    if (event.table === 'teams') {
      if (event.eventType === 'INSERT') {
        teams.value.push(event.new)
      } else if (event.eventType === 'UPDATE') {
        teams.value = teams.value.map((team) =>
          team.id === event.old.id ? event.new : team,
        )
      } else if (event.eventType === 'DELETE') {
        teams.value = teams.value.filter((team) => team.id !== event.old.id)
      }
    }

    if (event.table === 'user_teams') {
      await throttledRefreshTeamMembersByTeamId()
    }

    if (event.table === 'invite_teams') {
      await throttledRefreshTeamInvites()
    }

    if (
      (event.table === 'user_teams' || event.table === 'invite_teams') &&
      event.eventType === 'DELETE'
    ) {
      teamMembersByTeamId.value[event.old.team_id] = teamMembersByTeamId.value[
        event.old.team_id
      ].filter(
        ({ id, email }) =>
          id !== event.old.user_id && email !== event.old.email,
      )

      if (user.value?.email === event.old.email) {
        teamInvites.value = teamInvites.value.filter(
          ({ team_id }) => team_id !== event.old.team_id,
        )
      }
    }
  })

  return {
    teams,
    refreshTeams: throttledRefreshTeams,
    team,
    setTeamByName,
    teamMembersByTeamId,
    refreshTeamMembersByTeamId: throttledRefreshTeamMembersByTeamId,
    teamMembers,
    selfMember,
    isOwner,
    teamInvites,
    refreshTeamInvites: throttledRefreshTeamInvites,
  }
})
