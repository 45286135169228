export function useTeamRows() {
  return useAsyncData<AppTeam[]>(
    'teams',
    async () => {
      try {
        const supabase = useSupabaseClient<AppDatabase>()
        const { data, error } = await supabase
          .from('teams')
          .select('*')
          .order('created_at', { ascending: false })

        if (error) {
          throw error
        }

        return data ?? []
      } catch (error) {
        console.error(error)
      }

      return []
    },
    {
      default: () => [],
    },
  )
}
